<template>
  <XyzTransition appear duration="auto" >
    <div class="page-wrap">
      <div class="page-hero" xyz="fade down-5 rotate-right-50% stagger delay-10">
        <div class="hero-logo xyz-nested">
          <img style="width:100%;max-width:450px" alt="behrends.rocks logo" src="../assets/logo.png">
        </div>
        <p class="hero-text xyz-nested">WebDev | AppDev</p>
      </div>
      <br><br>
      <div class="page-features" xyz="fade flip-down stagger duration-10 delay-20 ease-out-back">
        <div class="feature-item xyz-nested">
          <h3>Contact</h3>
          <address>
            <i class="nes-icon is-small trophy"></i>&nbsp;service@behrends.rocks
          </address>
        </div>
      </div>
    </div>
  </XyzTransition>

</template>

<script>
export default {
  name: "main-page"
}
</script>

<style scoped>

</style>
